import { File, FilePdf } from '@phosphor-icons/react';
import { Image } from 'components';
import { determineFileType } from '../utils';
import type { ExtendedFile } from '../types';

export const FileTypePreview = ({ file, size }: { file: ExtendedFile; size: number }) => {
  const type = determineFileType(file);

  if (type === 'pdf') return <FilePdf data-testid="icon-pdf" size={size} />;
  if (type === 'text') return <FilePdf data-testid="icon-text" size={size} />;
  if (type === 'image')
    return (
      <Image
        data-testid="image-preview"
        src={file.preview}
        width={32}
        height={32}
        sx={{ borderRadius: 1, overflow: 'hidden' }}
        fit="cover"
        role="presentation"
      />
    );

  return <File data-testid="icon-default" size={size} />;
};
