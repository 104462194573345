/* istanbul ignore file -- @preserve */

import { configureAuth } from 'lib/react-query-auth';
import { userFn, loginFn, tokenFn, registerFn, logoutFn } from 'features/auth/utils/token';
import { Navigate } from 'react-router-dom';

const { AuthLoader } = configureAuth({
  userFn,
  loginFn,
  tokenFn,
  registerFn,
  logoutFn,
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <AuthLoader
      renderLoading={() => <div>Loading ...</div>}
      renderLogin={() => <Navigate to="/login" replace />}
      renderCompanySelect={() => <Navigate to="/switchcompany" replace />}
    >
      {children}
    </AuthLoader>
  );
};
