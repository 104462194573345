import React from 'react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient, persistOptions } from 'lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { DISABLE_DATA_PERSISTANCE } from 'config';

type TanstackQueryProviderProps = {
  children: React.ReactNode;
};

export const ReactQueryProvider = ({ children }: TanstackQueryProviderProps) => {
  return DISABLE_DATA_PERSISTANCE ? (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  ) : (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      {/* React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development' */}
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </PersistQueryClientProvider>
  );
};
