/* istanbul ignore file -- @preserve */

import { DOMAIN } from 'config';
import { LoginCredentials, Login } from '../types';
import {
  saveUserDataCookies,
  saveTokenCookies,
  deleteUserCookies,
  getUserCookies,
} from './cookies';

export const userFn = (): Login => {
  const cookies = getUserCookies();
  if (cookies.userData && !cookies.accessToken) {
    return { user: cookies.userData, token: null };
  }
  if (cookies.userData && cookies.accessToken) {
    return {
      user: cookies.userData,
      token: {
        access_token: cookies.accessToken,
        token_type: cookies.tokenType,
        expires_in: Number(cookies.expiresIn),
      },
      loginContext: cookies.loginContext,
    };
  }
  return null;
};

export const loginFn = async (user: LoginCredentials) => {
  const response = await fetch(`${DOMAIN}/api/v2.0/Login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  if (!response.ok) {
    throw new Error('Erorr logging in.');
  }
  const data = await response.json();
  saveUserDataCookies(data);
  if (!data.isMultiselect) {
    const tokenLogin = await tokenFn({ ...user, loginContext: data.loginContexts[0], user: data });
    return tokenLogin;
  }
  const login: Login = {
    user: data,
    token: null,
    Username: user.Username,
    Password: user.Password,
  };
  return login;
};

// eslint-disable-next-line require-await
export const logoutFn = async () => {
  deleteUserCookies();
  return;
};

// eslint-disable-next-line require-await
export const registerFn = async () => {
  return null;
};

export const tokenFn = async (user: LoginCredentials) => {
  const response = await fetch(`${DOMAIN}/api/v2.0/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      Username: user.Username,
      Password: user.Password,
      ForOrgId: user.loginContext?.designerOrganizationId || '',
      DesignerOrgId: user.loginContext?.designerOrganizationId || '',
    }),
  });
  if (!response.ok) {
    throw new Error('Error logging in.');
  }
  const data = await response.json();
  saveTokenCookies(data, user.loginContext);
  const login: Login = {
    user: user.user,
    token: data,
    loginContext: user.loginContext,
    Username: user.Username,
    Password: user.Password,
  };
  return login;
};
