import styled from '@emotion/styled';

export const ComponentStackTrace = ({ stack }: { stack: string }) => {
  const stackRows = stack.split('\n').filter(Boolean);

  const newRow = stackRows.map((row, index) => {
    let details;
    let component;

    if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
      const [c, d] = row.split('@');
      const cleanedDetails = d?.split('http://localhost:4200/').join('');

      component = `${c} `;
      details = cleanedDetails;
    }

    if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
      const [c, d] = row.substring(row.indexOf('at ') + 3).split(' ');

      component = `${c} `;
      details = d?.split('http://localhost:4200/').join('');

      if (c.includes('localhost')) {
        details = `${c?.split('http://localhost:4200/').join('')} `;
        component = '';
      }
    }

    return (
      <Pre key={row}>
        {!details && <At>@ </At>}
        {component && <ComponentPart>{component}</ComponentPart>}
        {details && <At>@ </At>}
        <DetailPart>{details}</DetailPart>
      </Pre>
    );
  });

  return <Container>{newRow}</Container>;
};

const Container = styled.div`
  font-size: 0.9rem;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  & pre:first-of-type {
    margin-top: 0;
  }
`;

const Pre = styled.pre`
  word-break: break-all;

  & a:hover {
    text-decoration: underlined;
  }
`;

const ComponentPart = styled.span`
  color: #cfa4ff;
`;

const At = styled.span`
  color: #e2aa53;
`;

const DetailPart = styled.span`
  color: cyan;
`;
