import { QueryClient, DefaultOptions } from '@tanstack/react-query';
import { QueryFn } from '../types';
import { api, handleRequest } from 'lib/api';
// Export functions & components
export * from '../indexedDB/';
export * from '../components/reactQueryLoader';

// Default query function
export const defaultQueryFn: QueryFn = ({ queryKey = [] }) => {
  return handleRequest(api.get(`/${queryKey[0]}`));
};

// Default config
export const defaultOptions: DefaultOptions = {
  queries: {
    queryFn: defaultQueryFn,
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    // refetchOnMount: false,
    refetchIntervalInBackground: false,
  },
};

// Create query client
export const queryClient = new QueryClient({ defaultOptions });
