import type { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { get, set, del } from 'idb-keyval';
import { QueryKey } from '@tanstack/react-query';

// If there are any queries that we always should refetch on load.
// They will still be cached in-memory but will not persist on reload
const excludedQueries: string[] = ['authenticated-user'];

export const createIDBPersister = (idbValidKey: IDBValidKey = 'reactQuery') => {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister;
};

export const persister = createIDBPersister('delogue-idb');

export const persistOptions = {
  persister,
  maxAge: 1000 * 60 * 60 * 24, // Persisted data should never be older than 1 day
  buster: APP_VERSION, // Bust all cache when our version change
  dehydrateOptions: {
    // Never dehydrate these queries
    shouldDehydrateQuery: ({ queryKey }: { queryKey: QueryKey }) => {
      return !(typeof queryKey[0] === 'string' && excludedQueries.includes(queryKey[0]));
    },
  },
};
