import { getUserCookies } from 'features/auth';
import { roles } from 'mocks/data/accessControl';
import type { FeatureFlag, Module, RoleAccess, Verb } from '../types';

export const unionAccess = (roleNames: string[]) => {
  let readScope: string[] = [];
  let writeScope: string[] = [];
  roleNames.forEach(roleName => {
    const access = roles.find(r => r.role === roleName)?.access;
    if (access) {
      access.forEach(roleAccess => {
        if (roleAccess.verb === 'read') {
          readScope = [...new Set([...readScope, ...roleAccess.scopes])];
        }
        if (roleAccess.verb === 'write') {
          writeScope = [...new Set([...writeScope, ...roleAccess.scopes])];
        }
      });
    }
  });
  const userAccess: RoleAccess[] = [
    {
      verb: 'write',
      scopes: writeScope,
    },
    {
      verb: 'read',
      scopes: readScope,
    },
  ];
  return userAccess;
};

const findScope = (access: RoleAccess[], page: string, verb: Verb) =>
  access.find(a => {
    if (a.verb !== verb) {
      return false;
    }
    if (a.scopes.some(scope => scope.startsWith(page) || page.startsWith(scope))) {
      return true;
    }
    return false;
  });

const sanitizePath = (path: string) => {
  if (path.startsWith('/')) {
    return path.slice(1);
  }
  return path;
};

export const checkAccess = (page: string, write = false, roleNames: string[] | null = null) => {
  const rolesArray = roleNames ? roleNames : getUserCookies()?.loginContext?.roleNames || [];
  const access = unionAccess(rolesArray);
  if (write && findScope(access, sanitizePath(page), 'write')) {
    return true;
  }
  if (!write && findScope(access, sanitizePath(page), 'read')) {
    return true;
  }
  return false;
};

export const checkFeatureFlag = (feature: string, flags: FeatureFlag[] | null = null) => {
  const flag = flags?.find(f => f.Name === feature);
  if (flag?.IsDisabled === 0) {
    return true;
  }
  return false;
};

export const checkModule = (module: string, modules: Module[] | null = null) => {
  const hasModule = modules?.find(m => m.Name === module);
  if (hasModule) {
    return true;
  }
  return false;
};

export const checkRole = (role: string, roleNames: string[] | null = null) => {
  const hasRole = roleNames?.find(r => r === role);
  if (hasRole) {
    return true;
  }
  return false;
};
