import { useUser, useLogout, useCompanySelect } from 'features/auth/hooks';
import { LoginContext } from 'features/auth/types';
import { useNavigate } from 'react-router-dom';
import { SwitchCompanyForm } from 'features/auth/components/SwitchCompanyForm';

const SwitchCompany = () => {
  const { data } = useUser({});
  const navigate = useNavigate();
  const logout = useLogout({ onSuccess: () => navigate('/') });
  const companySelect = useCompanySelect({ onSuccess: () => navigate('/') });

  const selectCompany = (loginContext: LoginContext) => {
    companySelect.mutate({
      loginContext,
      Username: data?.Username,
      Password: data?.Password,
      user: data?.user,
    });
  };

  const onLogout = () => {
    logout.mutate({});
  };

  return (
    <SwitchCompanyForm
      onSelectCompany={login => selectCompany(login)}
      onLogout={() => onLogout()}
    />
  );
};

export default SwitchCompany;
