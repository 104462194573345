import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    analytics: {
      page: () => void;
      identify: (id: string, traits: unknown) => void;
    };
    app_version: string;
  }
}

export const usePageView = () => {
  const analytics = window.analytics;
  const location = useLocation();
  const locationRef = useRef<string>('');

  // Make segment page() run on SPA route change
  useEffect(() => {
    if (!analytics) return;
    if (analytics && location.pathname !== locationRef?.current) {
      analytics.page();
      locationRef.current = location.pathname;
    }
  }, [location]);
};
