import { IconContext } from '@phosphor-icons/react';
import type { IconProps } from '@phosphor-icons/react';
import { colors } from 'styles/theme/colors';

export const IconProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: IconProps;
}) => {
  const defaultValue: IconProps = {
    size: 24,
    color: colors.black[500],
    weight: 'light',
  };

  return (
    <IconContext.Provider value={{ ...defaultValue, ...value }}>{children}</IconContext.Provider>
  );
};
