import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LoginRoutes } from './login';
import { AppRoutes } from './app';
import { ErrorBoundaryLayout } from 'components';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [...AppRoutes, ...LoginRoutes, { path: '*', element: <div>Not found</div> }],
  },
]);
