import styled from '@emotion/styled';
import { useLogout, useUser } from 'features/auth';
import { Link, useNavigate } from 'react-router-dom';

export const UserDetails = () => {
  const { data } = useUser({});
  const navigate = useNavigate();
  const logout = useLogout({ onSuccess: () => navigate('/') });

  const onLogout = () => {
    logout.mutate({});
  };

  return (
    <Container>
      <Upper>
        <Button onClick={() => onLogout()}>Switch company</Button>
        {/* <Link to="/switchcompany">Switch company</Link> disabled until api update */}
      </Upper>
      <Lower>
        <Link to="/help">Help</Link>
        <PipeDivider />
        <div>{data?.loginContext?.organizationName}</div>
        <PipeDivider />
        <div>{data?.loginContext?.name}</div>
        <PipeDivider />
        <Button onClick={() => onLogout()}>Logout</Button>
      </Lower>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  line-height: 0.8rem;
  gap: 0.25rem;
  padding: 1rem;
`;

const PipeDivider = styled.span`
  height: 0.85rem;
  width: 1px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 100%;
    top: 1px;
  }
`;

const Upper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Lower = styled.div`
  display: flex;
  gap: 0.35rem;
  align-items: center;

  & > div,
  & > a {
    padding-top: 3px;
  }
`;

const Button = styled.button`
  height: 1rem;
  display: block;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 0rem;
  font-size: 0.8rem;
`;
