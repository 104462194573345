import { action } from 'easy-peasy';
import { ModalManagerStore } from '../types';

export const modalManagerStore: ModalManagerStore = {
  activeId: '',
  modals: [],
  isModalListVisible: false,
  setModalListVisibility: action((state, payload) => {
    state.isModalListVisible = payload;
  }),
  setActiveId: action((state, payload) => {
    state.activeId = payload;
  }),
  add: action((state, payload) => {
    if (state.modals.filter(f => f.id === payload.id).length > 0) return;
    state.modals = [...state.modals, payload];
  }),
  remove: action((state, payload) => {
    if (state.modals.length === 1) state.isModalListVisible = false;
    state.modals = [...state.modals.filter(f => f.id !== payload)];
  }),
  clear: action(state => {
    state.modals = [];
  }),
};
