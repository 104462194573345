import type { Components, Theme } from '@mui/material';
import {
  basic,
  primary,
  secondary,
  tertiary,
  quaternary,
  quaternaryElevated,
} from 'components/button';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    quaternary: true;
    'quaternary-elevated': true;
  }
}

export const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      style: basic,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
    variants: [
      {
        props: { variant: 'primary' },
        style: primary,
      },
      {
        props: { variant: 'secondary' },
        style: secondary,
      },
      {
        props: { variant: 'tertiary' },
        style: tertiary,
      },
      {
        props: { variant: 'quaternary' },
        style: quaternary,
      },
      {
        props: { variant: 'quaternary-elevated' },
        style: quaternaryElevated,
      },
    ],
  },
};
