import styled from '@emotion/styled';
import { DOMAIN } from 'config';
import { Trans, t } from '@lingui/macro';

export const OldSiteLink = () => {
  return (
    <LinkContainer>
      <a
        href={DOMAIN}
        title={t`You are currently on v2 Delogue which is in beta stage, click here to go back to v1.`}
      >
        <Trans>Change to v1 Delogue</Trans>
      </a>
    </LinkContainer>
  );
};

const LinkContainer = styled.div`
  a {
    color: #595959;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background-color: #cce6ff;
  border: 1px solid #99c2ff;
`;
