import { useUser } from 'features/auth/hooks';
import { LoginContext } from 'features/auth/types';

type SwitchCompanyProps = {
  onSelectCompany: (loginContext: LoginContext) => void;
  onLogout: () => void;
};

export const SwitchCompanyForm = ({ onSelectCompany, onLogout }: SwitchCompanyProps) => {
  const { data } = useUser({});

  return (
    <>
      <h1>Select company</h1>
      <div>
        {data?.user?.loginContexts?.map((loginContext: LoginContext) => (
          <div key={loginContext?.id}>
            {loginContext?.organizationName}{' '}
            <button onClick={() => onSelectCompany(loginContext)}>Select</button>
          </div>
        ))}
      </div>
      <div>
        <button onClick={() => onLogout()}>Logout</button>
      </div>
    </>
  );
};
