import { Typography, styled } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { useStoreActions } from 'stores/global';
import { Modal } from './Modal';
import type { ModalProps } from '../types';

export const TaskBarItem = ({ id, taskBarTitle, trigger, onClose, ...rest }: ModalProps) => {
  const { setActiveId, remove } = useStoreActions(actions => actions.modalManager);

  const handleClose = (modalId: string) => {
    setActiveId('');
    remove(modalId);
    onClose && onClose();
  };

  return (
    <MinimizedModal key={id}>
      <Modal
        id={id}
        taskBarTitle={taskBarTitle}
        onClose={onClose}
        trigger={{
          ...trigger,
          Component: <MinimizedLabel variant="bodySmall">{taskBarTitle}</MinimizedLabel>,
        }}
        {...rest}
      />
      <Close data-testid="taskbar-item-button" onClick={() => handleClose(id)}>
        <X size={12} />
      </Close>
    </MinimizedModal>
  );
};

const MinimizedModal = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  background: theme.palette.surface.light,
  borderRadius: 4,
  boxShadow: theme.shadows[1],
  flex: 1,
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'start',
  cursor: 'pointer',
  border: 0,

  '& > div': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '&:hover > div': {
    background: theme.palette.action.hover,
  },
}));

const Close = styled('button')(({ theme }) => ({
  height: '100%',
  width: 36,
  aspectRatio: '1/1',
  flexGrow: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  borderLeft: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  background: theme.palette.surface.light,
  cursor: 'pointer',
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  '&:hover': {
    background: theme.palette.action.hover,
  },
}));

const MinimizedLabel = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  width: 150,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: 8,
  textAlign: 'left',
  color: theme.palette.text.primary,
  fontSize: '13px',
  lineHeight: '20px',
  '&:hover': {
    background: theme.palette.action.hover,
  },
}));
