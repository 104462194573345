import { colors } from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    surface: {
      main: string;
      light: string;
      dark: string;
      contrast: string;
    };
  }

  interface PaletteOptions {
    surface: {
      main: string;
      light: string;
      dark: string;
      contrast: string;
    };
  }

  interface PaletteColor {
    contrast?: string;
  }

  interface SimplePaletteColorOptions {
    contrast?: string;
  }
}

// All of these colors needs to be discussed with UX/Design team.
export const palette = {
  primary: {
    light: colors.palmGreen[400],
    main: colors.palmGreen[500],
    contrast: colors.white[500],
  },
  error: {
    main: colors.error[400],
    dark: colors.error[500],
    light: colors.error[300],
    contrast: colors.white[500],
  },
  success: {
    main: colors.success[400],
    dark: colors.success[500],
    light: colors.success[300],
    contrast: colors.black[500],
  },
  surface: {
    main: colors.white[600],
    light: colors.white[500],
    dark: colors.black[400],
    contrast: colors.black[500],
  },
};
