import { action } from 'easy-peasy';
import { FileUploadStore } from '../types';

export const fileUploadStore: FileUploadStore = {
  uploadData: [],
  setUploadData: action((state, payload) => {
    state.uploadData = payload;
  }),
  addUploadData: action((state, payload) => {
    const prev = state.uploadData.filter(f => f.id !== payload.id);

    state.uploadData = [...prev, payload].sort((a, b) => {
      return a.id.localeCompare(b.id) > b.id.localeCompare(a.id) ? 1 : -1;
    });
  }),
};
