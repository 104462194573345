import type { ExtendedFile } from '../types';

export const determineFileType = (file: ExtendedFile) => {
  const fileType = file.type;

  switch (true) {
    case fileType.startsWith('image/'):
      return 'image';
    case fileType === 'application/pdf':
      return 'pdf';
    case fileType.startsWith('text/'):
      return 'text';
  }
};
