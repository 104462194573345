import { useState, Fragment } from 'react';
import { Checkbox } from 'components/inputs';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import styled from '@emotion/styled';
import type { Item, CheckboxListProps, RecursiveCheckboxListProps } from './types';
import { flattenObjectIds, isAllChecked } from './utils';

export const CheckboxList = ({ items, onChange }: CheckboxListProps) => {
  const [checked, setChecked] = useState<number[]>([]);

  const toggleChecked = (id: number, isChecked: boolean) => {
    let newChecked = [];
    if (isChecked) {
      newChecked = [...checked, id];
    } else {
      newChecked = checked.filter(item => item !== id);
    }
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked, newChecked.length);
    }
  };

  const toggleAll = (subItems: Item[], isChecked: boolean) => {
    const subIds = flattenObjectIds(subItems);
    let newChecked = [];
    if (isChecked) {
      newChecked = [...new Set([...checked, ...subIds])];
    } else {
      newChecked = checked.filter(item => !subIds.includes(item));
    }
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked, newChecked.length);
    }
  };

  return (
    <>
      {items.map(item => {
        return (
          <RecursiveCheckboxList
            key={item.id}
            item={item}
            checked={checked}
            toggleAll={toggleAll}
            toggleChecked={toggleChecked}
          />
        );
      })}
    </>
  );
};

const RecursiveCheckboxList = ({
  item,
  checked,
  toggleAll,
  toggleChecked,
}: RecursiveCheckboxListProps) => {
  const [open, setOpen] = useState(
    item.subItems ? Object.fromEntries(item.subItems.map(subItem => [subItem.id, false])) : {}
  );
  const allChecked = isAllChecked(checked, item.subItems);
  const toggleOpen = (id: number, isOpen: boolean) => {
    setOpen(prev => ({ ...prev, [id]: isOpen }));
  };
  return (
    <Fragment key={item.id}>
      <Collapsable key={item.id}>
        {item.subItems?.length ? (
          <>
            <Checkbox
              indeterminate={allChecked.includesAny && !allChecked.includesAll}
              checked={allChecked.includesAll}
              label={item.label}
              onChange={e => item.subItems && toggleAll(item.subItems, e.target.checked)}
            />
            <Caret onClick={() => toggleOpen(item.id, !open[item.id])}>
              {open[item.id] ? <CaretUp size={16} /> : <CaretDown size={16} />}
            </Caret>
          </>
        ) : (
          <Checkbox
            checked={checked.indexOf(item.id) > -1}
            label={item.label}
            onChange={e => toggleChecked(item.id, e.target.checked)}
          />
        )}
      </Collapsable>
      {open[item.id] && item.subItems?.length ? (
        <SubItems>
          {item.subItems?.map(subItem => (
            <RecursiveCheckboxList
              key={subItem.id}
              item={subItem}
              checked={checked}
              toggleAll={toggleAll}
              toggleChecked={toggleChecked}
            />
          ))}
        </SubItems>
      ) : null}
    </Fragment>
  );
};

const Collapsable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

const Caret = styled.button`
  all: unset;
  cursor: pointer;
  margin-left: 16px;
`;
