export const ExampleRoutes = {
  path: 'example',
  lazy: () => import('../pages/example'),
  children: [
    {
      path: 'playwright',
      lazy: () => import('../pages/example/playwright'),
      children: [
        {
          path: 'masking',
          lazy: () => import('../pages/example/playwright/masking'),
        },
        {
          path: 'components',
          lazy: () => import('../pages/example/playwright/components'),
        },
      ],
    },
    {
      path: 'playground',
      lazy: () => import('../pages/example/playground'),
    },
  ],
};
