export const IS_DEV = import.meta.env.MODE === 'development';
export const DOMAIN = import.meta.env.VITE_DOMAIN || 'https://missing-domain.com';
export const SEGMENT_ANALYTICS_KEY = import.meta.env.VITE_SEGMENT_API_KEY || '';
export const DISABLE_DATA_PERSISTANCE = import.meta.env.VITE_DISABLE_DATA_PERSISTANCE;
export const DATE_FORMAT = 'DD-MM-YYYY';

export const MSW_LOGGING = import.meta.env.VITE_MSW_LOGGING
  ? import.meta.env.VITE_MSW_LOGGING === 'false'
  : true;
export const MSW_UNHANDLED_REQUESTS = import.meta.env.VITE_MSW_UNHANDLED_REQUESTS || 'bypass';
