import styled from '@emotion/styled';
import { setLocale } from 'features/i18n';

// This is a placeholder component until we have some
// UI design to implement.
export const LocaleSwitcher = () => {
  return (
    <Container>
      <span>Locale: </span>
      <Buttons>
        <button onClick={() => setLocale('en')}>En</button>
        <button onClick={() => setLocale('da')}>Da</button>
      </Buttons>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.25rem;
`;
