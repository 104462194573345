import { Button, Typography } from '@mui/material';
import { ComponentStackTrace } from './ComponentStackTrace';
import styled from '@emotion/styled';
import type { FallbackComponentProps } from './types';

export const FallbackComponent = ({
  error,
  componentStack,
  resetError,
}: FallbackComponentProps) => {
  return (
    <StyledFallbackComponent>
      <Wrapper>
        <Typography sx={{ marginBottom: '1.5rem' }} variant="headingMedium">
          Error occured when rendering a component.
        </Typography>
        <Typography sx={{ marginBottom: '1rem', color: 'error.main' }} variant="bodyLarge">
          {error.toString()}
        </Typography>
        <ComponentStackTrace stack={componentStack} />
        <Button variant="primary" sx={{ marginTop: '1rem' }} onClick={() => resetError()}>
          Reload page
        </Button>
      </Wrapper>
    </StyledFallbackComponent>
  );
};

const Wrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background-color: #181818;
  color: #c9c9c9;
  padding: 2rem 2.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 90vh;
  box-shadow: 3px 6px 23px 0px rgba(0, 0, 0, 0.45);

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledFallbackComponent = styled.div`
  padding: 2rem 1rem;
  background-color: #313030;
  color: #c9c9c9;
  height: 100vh;
`;
