import { configureAuth } from 'lib/react-query-auth';
import { userFn, loginFn, tokenFn, registerFn, logoutFn } from '../utils/token';

export const { useUser, useLogin, useCompanySelect, useLogout } = configureAuth({
  userFn,
  loginFn,
  tokenFn,
  registerFn,
  logoutFn,
});
