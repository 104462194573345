import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { checkAccess } from '../utils';

type AccessControlProps = {
  children: React.ReactNode;
  write?: boolean;
  fallback?: React.ReactNode;
  scope?: string;
  roleNames?: string[] | null;
};

export const AccessControl = ({
  children,
  write = false,
  fallback = null,
  scope = '',
  roleNames = null,
}: AccessControlProps) => {
  const { scope: scopeContext, roleNames: roleNamesContext } = useScope(scope);
  const currentRoleNames = roleNames || roleNamesContext;
  const hasAccess = checkAccess(scopeContext, write, currentRoleNames);
  if (scope && !hasAccess) {
    return (
      <ScopeContextProvider scope={scope} roleNames={roleNames}>
        {fallback}
      </ScopeContextProvider>
    );
  }
  if (scope && hasAccess) {
    return (
      <ScopeContextProvider scope={scope} roleNames={roleNames}>
        {children}
      </ScopeContextProvider>
    );
  }
  if (!hasAccess) {
    return fallback;
  }
  return children;
};

type ScopeContextType = {
  scope: string;
  roleNames: string[] | null;
};

const ScopeContext = createContext<ScopeContextType>({ scope: '', roleNames: null });

type AccessControlContextProps = {
  children: React.ReactNode;
} & ScopeContextType;

const ScopeContextProvider = ({ children, scope, roleNames = null }: AccessControlContextProps) => {
  return <ScopeContext.Provider value={{ scope, roleNames }}>{children}</ScopeContext.Provider>;
};

export const useScope = (scope = '') => {
  const location = useLocation();
  const { scope: scopeContext, roleNames } = useContext(ScopeContext);
  const currentScope = scope || scopeContext || location.pathname;
  return { scope: currentScope, roleNames };
};

export const useCheckAccess = (write = false) => {
  const { scope, roleNames } = useScope();
  return checkAccess(scope, write, roleNames);
};
