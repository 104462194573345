import { Checkbox as MuiCheckbox } from '@mui/material';
import styled from '@emotion/styled';
import type { SxProps } from '@mui/material';

type CheckboxProps = {
  checked: boolean;
  label?: React.ReactNode;
  id?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  sx?: SxProps;
  indeterminate?: boolean;
};

export const Checkbox = ({
  checked,
  label,
  id,
  defaultChecked,
  disabled,
  inputRef,
  onChange,
  sx,
  indeterminate,
}: CheckboxProps) => {
  if (label) {
    const htmlId = id || crypto.randomUUID();
    return (
      <LabelContainer>
        <MuiCheckbox
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          inputRef={inputRef}
          onChange={onChange}
          sx={sx}
          id={htmlId}
          indeterminate={indeterminate}
        />
        <Label htmlFor={htmlId}>{label}</Label>
      </LabelContainer>
    );
  }
  return (
    <MuiCheckbox
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      inputRef={inputRef}
      onChange={onChange}
      sx={sx}
      indeterminate={indeterminate}
    />
  );
};

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.label`
  cursor: pointer;
`;
