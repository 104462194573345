import { I18nProvider as LingUiProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { setLocale } from 'features/i18n';
import { useEffect } from 'react';

type I18nProviderProps = {
  children: React.ReactNode;
};

export const I18nProvider = ({ children }: I18nProviderProps) => {
  useEffect(() => {
    setLocale();
  }, []);

  return <LingUiProvider i18n={i18n}>{children}</LingUiProvider>;
};
