import { msg } from '@lingui/macro';

/*
  The following is a list of words that are used in the jargon component.
  The first word in each list is the default word used in a Clothes organization type.
  Next ones are Foot wear, Glasses, Jewellery, Interior, Fabric, Cosmetics.
*/

export const wordList = {
  Taskboard: [
    msg`Taskboard`,
    msg`Taskboard`,
    msg`Taskboard`,
    msg`Taskboard`,
    msg`Taskboard`,
    msg`Taskboard`,
    msg`Taskboard`,
  ],
  Dashboard: [
    msg`Dashboard`,
    msg`Dashboard`,
    msg`Dashboard`,
    msg`Dashboard`,
    msg`Dashboard`,
    msg`Dashboard`,
    msg`Dashboard`,
  ],
  Styles: [
    msg`Styles`,
    msg`Styles 2`,
    msg`styles 3`,
    msg`styles 4`,
    msg`styles 5`,
    msg`styles 6`,
    msg`styles 7`,
  ],
  Items: [
    msg`Items`,
    msg`Items 2`,
    msg`Items 3`,
    msg`Items 4`,
    msg`Items 5`,
    msg`Items 6`,
    msg`Items 7`,
  ],
  Report: [
    msg`Report`,
    msg`Report`,
    msg`Report`,
    msg`Report`,
    msg`Report`,
    msg`Report`,
    msg`Report`,
  ],
  Analytics: [
    msg`Analytics`,
    msg`Analytics`,
    msg`Analytics`,
    msg`Analytics`,
    msg`Analytics`,
    msg`Analytics`,
    msg`Analytics`,
  ],
  Marketing: [
    msg`Marketing`,
    msg`Marketing`,
    msg`Marketing`,
    msg`Marketing`,
    msg`Marketing`,
    msg`Marketing`,
    msg`Marketing`,
  ],
  Orders: [
    msg`Orders`,
    msg`Orders`,
    msg`Orders`,
    msg`Orders`,
    msg`Orders`,
    msg`Orders`,
    msg`Orders`,
  ],
  Compliance: [
    msg`Compliance`,
    msg`Compliance`,
    msg`Compliance`,
    msg`Compliance`,
    msg`Compliance`,
    msg`Compliance`,
    msg`Compliance`,
  ],
  Admin: [msg`Admin`, msg`Admin`, msg`Admin`, msg`Admin`, msg`Admin`, msg`Admin`, msg`Admin`],
};
